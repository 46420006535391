import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'


export default function DiscordCampaign() {

    const [campaignNumber, updateCampaign] = useState(1)


    useEffect(() => {
        if (window.localStorage.getItem('lepasaCampaignNumber') !== null) {
            updateCampaign(Number(window.localStorage.getItem('lepasaCampaignNumber')))
        } else {
            updateCampaign(getRandomNumber())
        }
    }, [])

    function getRandomNumber() {
        var random = Math.floor((Math.random() * 5) + 1)
        window.localStorage.setItem('lepasaCampaignNumber', random)
        return random
    }


    return (
        <>
            <div className="site-content">
                <Header isConnectButton={true} />
                <div className="header-block"></div>
                {
                    campaignNumber === 1 ? <div style={{ textAlign: 'center' }}>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdF647CzPrOuZCJj185gJ_kwH0-YaaNt-7s00HPqWOuJ5ifeA/viewform?embedded=true" width="100%" height="5067" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                    </div> : <></>
                }

                {
                    campaignNumber === 2 ? <div style={{ textAlign: 'center' }}>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSciVVPqHSrcu342lI4c2moEkio_oSflxCe6KeO2vfoudPVIvg/viewform?embedded=true" width="100%" height="4683" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                    </div> : <></>
                }

                {
                    campaignNumber === 3 ? <div style={{ textAlign: 'center' }}>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfnTxFTlCbk0iMl76Yq75ZO31JNPRjADsBiLnVSTXfFRvyHTQ/viewform?embedded=true" width="100%" height="4685" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                    </div> : <></>
                }

                {
                    campaignNumber === 4 ? <div style={{ textAlign: 'center' }}>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScjwwMYuUDPEDYNpTYmmsB45s7jcidUDl8cir28Tb0i-1o3mw/viewform?embedded=true" width="100%" height="4557" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                    </div> : <></>
                }

                {
                    campaignNumber === 5 ? <div style={{ textAlign: 'center' }}>

                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSca_AcSfbJvpHACJH3DgsXv77rAxkVVK14WUVbiWrX0-9izZQ/viewform?embedded=true" width="100%" height="4607" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                    </div> : <></>
                }

                <Footer />

            </div>
        </>
    )
}
