import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import eventBus from './EventBus'
import axios from "axios"
import * as lepaConstant from './Constant';
import { useHistory } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function MyCollection() {

    const [collection, setCollection] = useState(null)
    const [double, setDouble] = useState(false)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const [transactionHash, setTransactionHash] = useState(null)
    const [password, setPassword] = useState('')
    const [sourceFile, setSourceFile] = useState('')

    let history = useHistory()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirmation, setShowConfirmation] = useState(false);

    var apiCalling = 0


    useEffect(() => {
        if (window.localStorage.getItem('accountAddress') === null) {
            history.push("/")
        }
        setTimeout(() => {
            loadingCollection(window.localStorage.getItem('accountAddress'))
        }, 500)

        listenEvents()
    }, [])

    useEffect(() => () => removeEvents(), [])

    function removeEvents() {
        eventBus.remove("accoutsChanged")
        eventBus.remove("wrongNetwork")
        eventBus.remove("requestMagicboxGrantedCollection")
        eventBus.remove("requestMagicboxSuccessCollection")
    }

    function resetAPICalling() {
        apiCalling = apiCalling + 1
        setTimeout(() => {
            apiCalling = 0
        }, 800)
    }

    function getAccountAddress() {
        return window.localStorage.getItem('accountAddress')
    }

    function handleClosePopup() {
        setVisiblePassword(false)
        handleClose()
    }

    function listenEvents() {
        eventBus.on('accoutsChanged', (params) => {
            loadingCollection(params.data[0])
        })

        eventBus.on('wrongNetwork', (params) => {
            if (params.data) {
                history.push("/")
            }
        })

        eventBus.on('requestMagicboxGrantedCollection', (params) => {
            if (apiCalling === 0) {
                resetAPICalling()
                if (params.data) {
                    setShowConfirmation(true)
                    setTransactionHash(params.transactionHash)
                    updateTransactionHash(params.mintId, params.transactionHash)
                } else {
                    updateTransactionHash(params.mintId, params.transactionHash)
                }
            }
        })

        eventBus.on('requestMagicboxSuccessCollection', (params) => {
            setShowConfirmation(false)
            updateTransactionReceipt(params.mintId, params.status)
        })

    }

    function updateTransactionHash(mintId, transactionHash) {
        const txStatus = null !== transactionHash ? "PENDING" : "REJECTED"
        const temp = JSON.stringify({ "mintId": mintId, "transactionHash": transactionHash, "txStatus": txStatus })
        axios.post(lepaConstant.BASE_URL + "nft/submit-transaction", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                if (null != transactionHash) {
                    // history.push('my-collection')
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function loadingCollection(addr) {
        if (undefined !== getGlobal(lepaConstant.TOKEN) && getGlobal(lepaConstant.EXPIRY_TIME) > getCurrentTimestamp()) {
            loadCollections(addr)
        } else {
            authRequest(addr, true, null)
        }
    }

    function authRequest(addr, isLoadCollection, lepasa) {
        axios.post(lepaConstant.BASE_URL + "users/auth", {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                let token = response.data.token
                setGlobal(lepaConstant.TOKEN, token)
                var today = new Date();
                today.setHours(today.getHours() + 2)
                setGlobal(lepaConstant.EXPIRY_TIME, today.getTime())
                if (isLoadCollection)
                    loadCollections(addr)
                else
                    softMintNFT(lepasa)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function showPassword(visible) {
        setVisiblePassword(visible)
    }

    function getCurrentTimestamp() {
        var latest = new Date()
        return latest.getTime()
    }

    function isMaticChain(chainId) {
        //Mainnet
        // return (chainId === 137 || chainId === '0x89')
        //Testnet    
        return (chainId === 80001 || chainId === '0x13881')
    }

    function isBinanceChain(chainId) {
        //Meinnet
        // return (chainId === 56 || chainId === '0x38')
        //Testnet
        return (chainId === 97 || chainId === '0x61')
    }

    function isETHChain(chainId) {
        //Meinnet
        // return (chainId === 1 || chainId === '0x1')
        //Testnet
        return (chainId === 4 || chainId === '0x4')
    }

    function getChainName() {
        return "MATIC"
        // let chainNameReturn = null
        // if (isMaticChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "MATIC"
        // else if (isBinanceChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "BNB"
        // else if (isETHChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "ETH"
        // return chainNameReturn
    }

    function loadCollections(addr) {
        const temp = JSON.stringify({ "address": addr, "chainName": getChainName() })
        axios.post(lepaConstant.BASE_URL + "nft/get-user-nft", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                setCollection(response.data.data)
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401")
                    authRequest(addr, true, null)
                else
                    handleError(error.response.data.message)
            })
    }

    function getGlobal(name) {
        return window.localStorage.getItem(name)
    }

    function openSeaPage(lepasa) {
        let url = "https://opensea.io/assets/matic/" + lepasa.bc_addr + "/" + lepasa.token_id
        window.open(url, '_blank').focus();
    }

    function setGlobal(key, value) {
        window.localStorage.setItem(key, value)
    }

    function showBox(id) {
        if (document.getElementById('lcollection-' + id).className === "box") {
            document.getElementById('lcollection-' + id).className = "box show-box"
        } else {
            document.getElementById('lcollection-' + id).className = "box"
        }
    }

    function getName(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        return jsonData.name
    }

    function getDescription(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        return jsonData.description
    }

    function getImageURL(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        return jsonData.image
    }

    function softMintNFT(lepasa) {
        setDouble(true)
        const temp = JSON.stringify({ "nftId": lepasa.id, "userAddress": getAccountAddress() })
        axios.post(lepaConstant.BASE_URL + "nft/hard-mint-request", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                setDouble(false)
                eventBus.dispatch('requestMagicbox', { data: response.data.data, isCollection: true })
            })
            .catch(function (error) {
                setDouble(false)
                if (error.message === "Request failed with status code 401")
                    authRequest(getAccountAddress(), false, lepasa)
                else
                    handleError(error.response.data.message)
            })
    }

    function confirmTransaction() {
        let urlScan = "https://polygonscan.com/tx/" + transactionHash
        window.open(urlScan)
    }

    function openNFTPage(lepasa) {
        const win = window.open("/nft/0/" + lepasa.token_id, "_blank");
        win.focus();
    }

    function openPasswordTab(nftObject) {
        setSourceFile("https://lepasa.s3-accelerate.amazonaws.com/0/" + nftObject.file_name + ".zip")
        setPassword(nftObject.file_pass)
        handleShow()
    }

    function handleError(error) {
        toast.error(error)
    }

    function getKey(attr, j) {
        return attr.value + "-" + j
    }

    function getAttributes(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        return jsonData.attributes
    }

    function updateTransactionReceipt(mintId, status) {
        const txStatus = status ? "SUCCESS" : "FAILED"
        const temp = JSON.stringify({ "mintId": mintId, "transactionHash": transactionHash, "txStatus": txStatus })

        axios.post(lepaConstant.BASE_URL + "nft/submit-transaction", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                if (status) {
                    window.location.reload()
                } else {
                    toast.error("Transaction Error on Polygon.")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <>
            <div className="site-content">
                <Header />
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className="header-block"></div>

                <main className="main-content">
                    <div className="art-section section my-collection">
                        <div className="container">
                            <div className="page-top">
                                <h1>My Collection</h1>
                            </div>
                            <div className="art-list row">
                                {null !== collection ? (

                                    collection.length > 0 ? (
                                        collection.map((lepasa, i) => {
                                            return <div className="col-md-6 col-lg-4" key={`lepasa-nft-${i}`}>
                                                {/* "box show-box"> */}
                                                <div className="box" id={`lcollection-${i}`}>
                                                    <div className="image3">
                                                        <img onClick={() => openNFTPage(lepasa)} src={getImageURL(lepasa)} alt="Lepasa NFT" />
                                                        <OverlayTrigger placement="left-end" key={`tooltip-${i}`}
                                                            overlay={
                                                                <Tooltip className="art-tooltip light-tooltip" id="boxTooltip">{getDescription(lepasa)}</Tooltip>
                                                            }>
                                                            <div className="info"><img src="/assets/img/circle-info.svg" alt="Info" /></div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <button className="btn show-button" onClick={() => showBox(i)}>{getName(lepasa)}</button>
                                                    <div className="box-overlay">
                                                        <div className="box-head" onClick={() => showBox(i)}>{getName(lepasa)}</div>
                                                        <div className="box-body">
                                                            <ul className="row">
                                                                {getAttributes(lepasa).map((attr, j) => {
                                                                    return <li key={getKey(attr, j)} className="col-6"><div className="item"><div><strong>{attr.trait_type}</strong>  <span>{attr.value}</span></div></div></li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="box-bottom">
                                                            <OverlayTrigger placement="bottom" key={`tooltip-mint-${i}`}
                                                                overlay={
                                                                    <Tooltip>Hard Mint</Tooltip>
                                                                }>
                                                                {lepasa.mint_status === 'HARD-MINT' ? <><button onClick={() => openSeaPage(lepasa)} className="first-btn btn btn-light opensea"><img src="/assets/img/spensea.png" alt="Source File" />OpenSea</button>
                                                                    <button onClick={() => openPasswordTab(lepasa)} className="btn btn-light"><img src="/assets/img/spensea.svg" alt="Source File" /></button></>
                                                                    :
                                                                    <button disabled={double} onClick={() => softMintNFT(lepasa)} className="first-btn btn btn-primary" >Mint NFT</button>}
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    ) : (<div className="empty-box col-12">
                                        <h3>No results to show</h3>
                                        <p>Your Lepasa collection is empty.</p>
                                    </div>)
                                ) : (<div className="empty-box col-12">
                                    <h3>Loading...</h3>
                                </div>)
                                }
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />

            </div>

            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Waiting for confirmation</h3>
                    <p className="p2" onClick={() => confirmTransaction()}>View Transaction</p>
                </Modal.Body>
            </Modal>

            <Modal show={show} onHide={() => handleClosePopup()} backdrop="static" className="source-model" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Download Source File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center"><a href={sourceFile} className="btn btn-outline-primary btn-sm downloadBtn" target="_blank" rel="noreferrer"> <img src="/assets/img/download.svg" alt="download" /> Downlaod Now</a></div>
                    <br />
                    <div className="from-group">
                        <label>Password</label>
                        <div className="input relativeContainer">
                            {visiblePassword ?
                                <input type="text" value={password} className="form-control" readOnly />
                                :
                                <input type="password" value="asdasdasdasdasd" className="form-control" id="password" readOnly />
                            }
                            {!visiblePassword ? <button className='passBtnRight' onClick={() => showPassword(true)}>View</button> : <button className='passBtnRight' onClick={() => showPassword(false)}>Hide</button>}

                        </div>
                    </div>
                    <div className="from-group">
                        {/* <label>Password</label> */}
                        <div>
                            The source zip file contains the blender source and textures required to use the 3D character in any game or 3d software. use the password shown above to unzip the file after download.
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
