import React, { useEffect, useState } from 'react'
import eventBus from './EventBus'

export default function Footer() {

    const [cookies, setCookies] = useState(true)
    const [isConnected, updateConnection] = useState(false)
    const [double, setDouble] = useState(false)


    useEffect(() => {
        if (window.localStorage.getItem('cookiesPermission') !== null) {
            setCookies(false)
        }

        if (window.localStorage.getItem('accountAddress') !== null) {
            updateConnection(true)
        }

        eventBus.on('updateConnectionEvent', (params) => {
            updateConnection(params.data)
        })

        return () => {
            eventBus.remove("updateConnectionEvent")
        }

    }, [])

    function disableCookies() {
        window.localStorage.setItem('cookiesPermission', "true")
        setCookies(false)
    }

    function addMetaMask() {
        setDouble(true)
        setTimeout(() => {
            setDouble(false)
        }, 3000)
        eventBus.dispatch('addMetaMask', {})
    }

    return (
        <footer id="footer" className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src="/assets/img/lepasa-logo-light.svg" alt="Lepasa Logo Light" className="footer-logo" />
                        <p>We're building an ecosystem that will bring everyone who believes in innovation to join the Crypto & NFT revolution. The world is moving on to witness an unprecedented pace. With Lepasa, you can experience all possible opportunities and fun.</p>

                        {isConnected ?
                            <div className='addLepaTokenBtnBox'>
                                <button disabled={double} className='addLepaTokenBtn' type='button' onClick={addMetaMask} ><img className='btnIconFtr' src='/assets/img/metamask.svg' alt="lepasa metamask" /> Add Lepa Token</button>
                            </div>
                            : <></>}
                    </div>
                    <div className="col-md-3 social-list">
                        <h3>Social Media</h3>
                        <a href="https://lepasa.medium.com/" rel="noreferrer" target="_blank"><img src="/assets/img/medium-m.png" alt="Medium" /></a>
                        <a href="https://twitter.com/lepasaorg" rel="noreferrer" target="_blank"><img src="/assets/img/twitter.png" alt="Twitter" /></a>
                        <a href="https://t.me/lepasa" rel="noreferrer" target="_blank"><img src="/assets/img/telegram-plane.png" alt="Telegram" /></a>
                        <a href="https://discord.gg/fUeCwJ9y5M" rel="noreferrer" target="_blank"><img src="/assets/img/discord.svg" alt="Discord" /></a>
                        <br /><br />
                        <h3>Listed on</h3>
                        <a href="https://coinmarketcap.com/currencies/lepasa" rel="noreferrer" target="_blank"><img src="/assets/img/coinmarket.svg" alt="Coin Market Cap" width="22" /></a>
                        <a href="https://www.coingecko.com/en/coins/lepasa" rel="noreferrer" target="_blank"><img src="/assets/img/coingecko.svg" alt="Coin Gecko" /></a>
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86" rel="noreferrer" target="_blank"><img src="/assets/img/pancake.svg" alt="PancakeSwap" /></a>
                        <a href="https://quickswap.exchange/#/swap?outputCurrency=0xF9a4BBAa7fA1DD2352F1A47d6d3fcfF259A6D05F" rel="noreferrer" target="_blank"><img src="/assets/img/quickswap.svg" alt="QuickSwap" /></a>
                        <a href="https://app.uniswap.org/#/swap?outputCurrency=0xbBa6C7c7d673c48d90069ad2e9d2fE587fcB6bc3" rel="noreferrer" target="_blank"><img src="/assets/img/uniswap.svg" alt="UniSwap" /></a>

                    </div>
                    <div className="col-md-3">
                        <div className="social-list">
                            <h3>Smart Contract</h3>
                            <a href="https://etherscan.io/token/0xbba6c7c7d673c48d90069ad2e9d2fe587fcb6bc3" rel="noreferrer" target="_blank"><img src="/assets/img/eth.svg" alt="ETH Logo" width="14" /></a>
                            <a href="https://bscscan.com/token/0xa4050aa9b76ccdae1a6a8b2f3e8627cdc1546d86" rel="noreferrer" target="_blank"><img src="/assets/img/bnb.svg" alt="BMB Logo" width="22" /></a>
                            <a href="https://polygonscan.com/token/0xF9a4BBAa7fA1DD2352F1A47d6d3fcfF259A6D05F" rel="noreferrer" target="_blank"><img src="/assets/img/polygon.svg" alt="Polygon Logo" width="20" /></a>
                        </div>
                        <h3>&nbsp;</h3>
                        <ul>
                            <li><a href="https://www.lepasa.com/assets/docs/whitepaper.pdf" rel="noreferrer" target="_blank"> <img src="/assets/img/external-link.svg" alt="" style={{ width: '16px' }} /> Whitepaper</a></li>
                            {/* <li><a href="https://etherscan.io/address/0xbba6c7c7d673c48d90069ad2e9d2fe587fcb6bc3" rel="noreferrer" target="_blank"> <img src="/assets/img/file-invoice.svg" alt="" />Smart Contract</a></li> */}
                            <li><a href="https://www.certik.com/projects/lepasa" rel="noreferrer" target="_blank"> <img src="/assets/img/certic.svg" alt="" />Contract Audit</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright">
                &copy; 2022 Lepasa. All right reserved.
            </div>
            {cookies ?
                <div id="cookieModal" className="cookie-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10">
                                <p>We use cookies to offer you a better browsing experience,
                                    analyse site traffic, personalise content, and serve targeted
                                    advertisements. Read about how we use cookies and how you can
                                    control them on our Privacy Policy. If you continue to use this
                                    site, you consent to our use of cookies.</p>
                            </div>
                            <div className="col-sm-2 text-right">
                                <button onClick={() => disableCookies()} data-bb-handler="ok" type="button" className="btn btn-primary">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>}
        </footer>
    )
}
