import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Chart from "react-apexcharts";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';

export default function Token() {

    const [chartState] = useState(
        {
            seriesDistribution: [39, 1, 10, 25, 5, 10, 10],
            optionsDistribution: {
                chart: {
                    width: '100%',
                    type: 'polarArea'
                },
                labels: ['Strategic & Private Distribution', 'Public Sale', 'Team', 'Marketing & Business Development', 'Advisors', 'Foundational Reserve', 'Liquidity'],
                fill: {
                    opacity: 1
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                yaxis: {
                    show: false,
                    labels: {
                        formatter: (value) => { return value + "%" },
                    },
                },
                legend: {
                    position: 'bottom'
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0
                        },
                        spokes: {
                            strokeWidth: 0
                        },
                    }
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        shadeTo: 'light',
                        shadeIntensity: 0.6,
                        color: '#FFC73E'
                    }
                }
            },
            seriesUsage: [40, 20, 10, 30],
            optionsUsage: {
                chart: {
                    width: '100%',
                    type: 'polarArea'
                },
                labels: ['Development', 'Marketing actions', 'Legal and Accountancy', 'Liquidity to exchanges'],
                fill: {
                    opacity: 1
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                yaxis: {
                    show: false,
                    labels: {
                        formatter: (value) => { return value + "%" },
                    },
                },
                legend: {
                    position: 'bottom'
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0
                        },
                        spokes: {
                            strokeWidth: 0
                        },
                    }
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        shadeTo: 'light',
                        shadeIntensity: 0.6,
                        color: '#FFC73E'
                    }
                }
            }
        }
    )

    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="main-content">

                <section className="section home-section1">
                        <div className="container">
                            <h2><span>$LEPA</span> TOKEN FOR ECOSYSTEM</h2>
                            <p className="p1">Lepasa legendaries are exclusively crafted for the Lepasa community<br/> and can be grabbed with <strong>$LEPA</strong> tokens only.</p>
                            <div className="form-row">
                                <div className="col-md-3">
                                    <div className="box">
                                        <img src="/assets/img/high-liquidity.png" alt="High Liquidity"/>
                                        <h3>High Liquidity</h3>
                                        <p>The $LEPA token provides enough liquidity to project for swapping between NFT and token</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box">
                                        <img src="/assets/img/hyper-deflation.png" alt="Hyper Deflation"/>
                                        <h3>Hyper Deflation</h3>
                                        <p>Each swap burns $LEPA token, that ensures continuous scarcity.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box">
                                        <img src="/assets/img/staking.png" alt="Staking"/>
                                        <h3>Staking</h3>
                                        <p>Stake Lepasa NFTs and earn Lepa tokens in rewards.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box">
                                        <img src="/assets/img/crosschain.png" alt="Crosschain"/>
                                        <h3>Crosschain</h3>
                                        <p>Access $LEPA on multiple public blockchains.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="tokenomics-section section">
                        <div className="container">
                            <div className="page-top">
                                <h1>LEPASA Tokenomics</h1>
                                <p>It is important for you to know economics of the tokens and how we are going to utilise the funds.</p>
                            </div>
                            <div className="row token-info">
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="image"><img src="./assets/img/total.svg" alt="" /></div>
                                        <div className="value">100 M</div>
                                        <h3>Total Supply</h3>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="image"><img src="./assets/img/initial-circulating-supply.svg" alt="" /></div>
                                        <div className="value">Less then 10%</div>
                                        <h3>Initial Circulating Supply</h3>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <OwlCarousel id="token-carousel" className="token-box owl-carousel"
                                        loop dots items={1} smartSpeed={500} >
                                        <div className="box">
                                            <div className="image"><img src="./assets/img/seed-price.svg" alt="Public Sale" /></div>
                                            <div className="value">$0.10</div>
                                            <h3>Public Sale</h3>
                                        </div>
                                        <div className="box">
                                            <div className="image"><img src="./assets/img/listing.svg" alt="Listing Price" /></div>
                                            <div className="value">$0.15</div>
                                            <h3>Listing Price</h3>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <div className="tokenomics-detail section">
                        <div className="container">
                            <div className="top">
                                <h2>Token Distribution</h2>
                                <p>Everything you want know about Lepasa Token Distribution.</p>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <ul>
                                        <li><strong>1 % Public sale</strong> - Price: $0.10</li>
                                        <li><strong>Listing Price</strong> - $0.15</li>
                                        <li><strong>Strategic &amp; Private Distribution</strong> - 10% shall be vested on-listing, Then daily vesting over a period of 365 days.</li>
                                        <li><strong>Foundational Reserve</strong> - After 1 year, Daily vesting over 365 days.</li>
                                        <li><strong>Marketing &amp; Business Development</strong> - 10% vested on listing, Then daily vesting over 365 days.</li>
                                        <li><strong>Liquidity</strong> - 10% of the total supply is allocated for liquidity.</li>
                                        <li><strong>Team</strong> - After 1 year, Daily vesting over 365 days.</li>
                                        <li><strong>Advisors</strong> - After 1 year, Daily vesting over 365 days.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <Chart
                                        series={chartState.seriesDistribution}
                                        options={chartState.optionsDistribution}
                                        type="polarArea"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tokenomics-detail section bg">
                        <div className="container">
                            <div className="top">
                                <h2>LEPASA Fund Usage</h2>
                                <p>This is how the funding is going to be used.</p>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-6 order-md-2">
                                    <ul>
                                        <li>40% of the funds will be used to support the <strong>development.</strong></li>
                                        <li><strong>Marketing actions</strong> such as go-to market strategy will account for 20% of the total funds raised.</li>
                                        <li><strong>Legal and Accountancy</strong> will represent 10% of the total funds raised.</li>
                                        <li>30% of funds will be used to provide <strong>liquidity to exchanges.</strong></li>
                                    </ul>
                                </div>
                                <div className="col-md-6 order-md-1">
                                    <Chart
                                        series={chartState.seriesUsage}
                                        options={chartState.optionsUsage}
                                        type="polarArea"
                                    />
                                    <div id="chartUsage"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />

            </div>
        </>
    )
}
