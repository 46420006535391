import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Dashboard() {

    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="main-content dashboard-page">
                    <div className="tokenomics-section section">
                        <div className="container">
                            <div className="page-top">
                                <h1>Dashboard</h1>
                                <p>It is important for you to know economics of the tokens and how we are going to utilise the funds.</p>
                            </div>
                            <div className="text-center"><span className="btn btn-primary btn-lg" >Connect Wallet</span></div>
                            {/* <div className="row token-info">
                                <div className="col-md-3">
                                    <div className="box">
                                        <div className="value">100</div>
                                        <h3>Allocation</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box">
                                        <div className="value">50</div>
                                        <h3>Unlocked</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box">
                                        <div className="value">20</div>
                                        <h3>Claimed</h3>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box balance">
                                        <div className="value">30</div>
                                        <h3>Current Balance</h3>
                                    </div>
                                </div>
                            </div> */}
                            <div className="bottom">Vesting End Date : 22/09/2022</div>
                        </div>
                    </div>

                </main>

                <Footer />

            </div>
        </>
    )
}
