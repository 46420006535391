import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Stake(props) {

    const history = useHistory()

    useEffect(() => {
        if (window.localStorage.getItem('accountAddress') === null) {
            history.push("/")
        }

    }, [])

    function stakingPage() {
        history.push("/stake-detail")
    }

    return (
        <>
            <div className="site-content">
                <Header />

                <div className="header-block"></div>

                <main className="main-content">
                    <section className="vip-banner section">
                        <div className="container">
                            <div className='row align-items-center'>
                                <div className='col-md-6 text-right order-md-2'>
                                    <img src="/assets/img/magicbox-collection.png" className="bannerMainImg" alt="" />
                                </div>
                                <div className='col-md-6 order-md-1'>
                                    <div className='vip-page-top'>
                                        <div className='page-top text-left headingLeftAlign'>
                                            <h1>
                                                STAKE YOUR NFTS
                                            </h1>
                                        </div>
                                    </div>
                                    <br />
                                    {/* <p className="p1 mr-md-4" style={{ color: '#f44336' }}>Staking is closed now. You will be notified for next slots.</p> */}
                                    <p className='p1 mr-md-4 magicBoxBannerParag'>
                                        <div className="stakeTxt">Lepasa <span className="semiBold">“First NFT Staking Plan”</span> with <span className="semiBold">20%</span> to <span className="semiBold">125% APY</span><sup>*</sup></div>
                                        <div className="stakeTxt">Now earn passive income with your digital Lepasa collection by
                                            staking your NFTs and earning annual yield.</div>
                                        <div className="stakeTxt">Gates will close by <span className="semiBold">Wednesday 16th Feb, 10.00 AM UTC</span></div>
                                        <div className="starInfo"><sup className="redTxtClr">*</sup> depends upon number of NFTs Staked.</div>
                                    </p>
                                    <a onClick={stakingPage} href='javascript:void(0)' className='btn btn-primary btn-lg'>Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    )
}
