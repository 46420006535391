import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import eventBus from './EventBus'
import ConnectButton from './ConnectButton.js'
import { Navbar, Modal, Button, Dropdown } from 'react-bootstrap'
import Jazzicon from '@metamask/jazzicon'
// import Countdown from 'react-countdown'

export default function Header(props) {

  const isButton = undefined === props.isConnectButton
  const history = useHistory()
  const [isConnected, updateConnection] = useState(false)
  const [accountAdd, setaccountAdd] = useState(null)
  const [chainName, setChainName] = useState('')
  const [accountAddress, setaccountAddress] = useState(null)
  const [selectCurrency, updateCurrency] = useState(' Select')
  const [selectedCurrencySeq, updateCurrencySeq] = useState(0)
  const [isMetaMask, updateMetamask] = useState(0)
  const [providerName, setProviderName] = useState('')
  const [currencyURL, updateCurrencyURL] = useState('')
  const [headerHighlight, setheaderHighlight] = useState(0)
  const [copyText, setcopyText] = useState('Copy Address')

  useEffect(() => {
    // Anything in here is fired on component mount.
    let currenctURL = window.location.href
    if (currenctURL.indexOf('my-collection') > -1) {
      setheaderHighlight(1)
    } else if (currenctURL.indexOf('token') > -1) {
      setheaderHighlight(2)
    } else if (currenctURL.indexOf('collection') > -1) {
      setheaderHighlight(3)
    } else if (currenctURL.indexOf('team') > -1) {
      setheaderHighlight(4)
    } else if (currenctURL.indexOf('magicbox') > -1) {
      setheaderHighlight(5)
    } else if (currenctURL.indexOf('stak') > -1) {
      setheaderHighlight(6)
    }
    if (window.localStorage.getItem('accountAddress') !== null) {
      updateConnection(true)
      setDisplayAdd(window.localStorage.getItem('accountAddress'))
    }
    if (window.localStorage.getItem('chainNameString') !== null) {
      setChainName(window.localStorage.getItem('chainNameString'))
    }
    if (window.localStorage.getItem('provideName') !== null) {
      setProviderName(window.localStorage.getItem('provideName'))
    }
    window.scrollTo(0, 0)

    listenEvents()

    return () => {
      removeEvents()
    }
  }, [])


  function listenEvents() {
    eventBus.on('accountBalEvent', (params) => {
      updateConnection(true)
    })

    eventBus.on('transactionHashInitiated', (params) => {
    })

    eventBus.on('chainChanged', (params) => {
      window.localStorage.setItem('chainNameString', params.data)
      setChainName(params.data)
    })

    eventBus.on('popupCurrency', (params) => {
      if ((params.provider === 0 || params.provider === 1) && (null === window.localStorage.getItem('providerName') || '' === window.localStorage.getItem('providerName'))) {
        setModalShow(true)
        updateMetamask(params.provider)
        if (null !== window.localStorage.getItem('chainNameString')) {
          if ('Binance' === window.localStorage.getItem('chainNameString')) {
            updateCurrencyRequest(3)
          } else if ('Matic' === window.localStorage.getItem('chainNameString')) {
            updateCurrencyRequest(2)
          } else if ('ETH' === window.localStorage.getItem('chainNameString')) {
            updateCurrencyRequest(1)
          }
        }
        setTimeout(() => {
          window.localStorage.setItem('providerName', params.provider)
        }, 1000)
      }
    })

    eventBus.on('registrationStatus', (params) => {
      updateConnection(params.data)
      if (params.data) {
        let provideName = "Coinbase"
        if (params.provider === 1) {
          provideName = "Metamask"
        } else if (params.provider === 0) {
          provideName = "WalletConnect"
        }
        setProviderName(provideName)
        window.localStorage.setItem('provideName', provideName)
      }
    })

    eventBus.on('accountEvent', (params) => {
      setDisplayAdd(params.data)
    })

    eventBus.on('chainNameMagicbox', (params) => {
      setChainName(params.data)
    })

    eventBus.on('accountConnectedMagicbox', (params) => {
      updateConnection(true)
      setDisplayAddress(params.data)
    })

    eventBus.on('updateConnectionEvent', (params) => {
      updateConnection(params.data)
    })

    eventBus.on('sendAccountEvent', (params) => {
      setDisplayAdd(params.data)
    })

    eventBus.on('chainNameEvent', (params) => {
      let chainNameValue = params.data
      window.localStorage.setItem('chainNameString', chainNameValue)
      setChainName(chainNameValue)
    })
  }

  function removeEvents() {
    eventBus.remove("transactionHashInitiated")
    eventBus.remove("accountBalEvent")
    eventBus.remove("chainChanged")
    eventBus.remove("accountEvent")
    eventBus.remove("popupCurrency")
    eventBus.remove("sendAccountEvent")
    eventBus.remove("updateConnectionEvent")
    eventBus.remove("chainNameEvent")
    eventBus.remove("accountConnectedMagicbox")
    eventBus.remove("chainNameMagicbox")
  }

  function setAccountIdentication(accountMT) {
    setTimeout(() => {
      if (chainName !== "Wrong Network" && isButton) {
        if (null !== document.getElementById("accountIdentication")) {
          document.getElementById("accountIdentication").innerHTML = ""
          document.getElementById("accountIdentication").appendChild(Jazzicon(24, parseInt(accountMT.slice(2, 10), 16)))
          document.getElementById("accountIdentication-mobile").innerHTML = ""
          document.getElementById("accountIdentication-mobile").appendChild(Jazzicon(24, parseInt(accountMT.slice(2, 10), 16)))
        }
      }
    }, 1000)
  }

  function reconnectWallet(reconnectType) {
    setinfoModalShow(false)
    eventBus.dispatch('reconnectWallet', { isWalletConnect: reconnectType })
  }

  function headerClickHandle(id, url) {
    history.push(url)
  }

  function getCurrencyURL() {
    let url = "/assets/img/eth.png"
    if (chainName === "Matic") {
      url = "/assets/img/matic.png"
    } else if (chainName === "Binance") {
      url = "/assets/img/bnb.png"
    }
    return url
  }

  function updateCurrencyRequest(currencySeq) {
    updateCurrencySeq(currencySeq)
    if (currencySeq === 1) {
      updateCurrency(' Ethereum Mainnet')
      updateCurrencyURL('assets/img/eth.png')
    } else if (currencySeq === 2) {
      updateCurrency(' Polygon')
      updateCurrencyURL('assets/img/matic.png')
    } else if (currencySeq === 3) {
      updateCurrency(' Binance Smartchain')
      updateCurrencyURL('assets/img/bnb.png')
    } else {
      updateCurrencyURL('')
    }
  }

  function getBlockchainName(name) {
    let result = "binance smartchain"
    if (name === "eth") {
      result = "ethereum mainnet"
    } else if (name === "matic") {
      result = "polygon"
    }
    return result
  }

  function confirmCurrency() {
    if (selectCurrency !== ' Select') {
      if (isMetaMask) {
        eventBus.dispatch('confirmCurrency', { data: selectCurrency, currencyNumber: selectedCurrencySeq })
      } else {
        if (!(selectCurrency.trim().toLowerCase() === getBlockchainName(chainName.trim().toLowerCase())))
          infoHandleShow()
      }
      setModalShow(false)
    } else {
      alert("Please Select Currency")
    }
  }

  function openReferenceLink() {
    let url = "https://ipfs.io/ipfs/QmcB24jWVFgoPRxaWiFFuNfpqQxsJuu9DrECfjQzuic9Zv"
    let win = window.open(url, '_blank')
    win.focus()
  }

  function setDisplayAdd(accountAddress) {
    // eventBus.dispatch('accountEventHeader', { data: accountAddress });
    eventBus.dispatch('accountConnectedHeader', { data: accountAddress })
    setDisplayAddress(accountAddress)
  }

  function setDisplayAddress(accountAddress) {
    window.localStorage.setItem('accountAddress', accountAddress)
    setaccountAddress(accountAddress)
    setAccountIdentication(accountAddress)
    let add =
      accountAddress.substring(0, 6) +
      '...' +
      accountAddress.substring(accountAddress.length - 5, accountAddress.length)
    setaccountAdd(add)
  }

  function showDisconnectModel() {
    handleClose()
    eventBus.dispatch('disconnectModel', { data: null })
  }

  function openExplorer() {
    let url = null
    if (chainName === "ETH") {
      url = 'https://ropsten.etherscan.io/address/' + accountAddress // Testnet
      // url = 'https://etherscan.io/address/' + accountAddress // Mainnet
    } else if (chainName === "Matic") {
      url = 'https://explorer-mumbai.maticvigil.com/address/' + accountAddress // Testnet
      // url = 'https://explorer-mainnet.maticvigil.com/address/' + accountAddress // Mainnet
    } else if (chainName === "Binance") {
      url = 'https://testnet.bscscan.com/address/' + accountAddress // Testnet
      // url = 'https://bscscan.com/address/' + accountAddress // Mainnet
    } else {
      alert("Wrong Network")
      return
    }
    let win = window.open(url, '_blank')
    win.focus()
  }

  function getSelectedChainName() {
    let chain = "Binance"
    if (selectCurrency.trim() === 'Ethereum Mainnet') {
      chain = "Ethereum"
    } else if (selectCurrency.trim() === 'Polygon') {
      chain = "Matic"
    }
    return chain
  }

  function copyAddress() {
    navigator.clipboard.writeText(accountAddress)
    setcopyText('Copied')
    setTimeout(() => {
      setcopyText('Copy Address')
    }, 500)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [modalShow, setModalShow] = useState(false)
  const [infoModalShow, setinfoModalShow] = useState(false)
  const infoHandleClose = () => setinfoModalShow(false)
  const infoHandleShow = () => setinfoModalShow(true)

  return (
    <>
      {/* <div className="site-top" onClick={() => headerClickHandle(6, '/staking')}><strong>Lepasa NFT Staking closing at 16th Feb 2022 10:00 AM UTC. Hurry Up. Time Left - <Countdown date={1645005600000} /> </strong></div> */}
      <header id="header" className="site-header">
        <div className="container">
          <Navbar className="header_main" expand="md">
            <Navbar.Brand className="logo mr-auto" href="/"><img src="/assets/img/lepasa-logo.svg" alt="" /></Navbar.Brand>
            {isButton ?
              isConnected ? (
                <div>
                  {
                    chainName === 'Wrong Network' ? (
                      <Dropdown className="wrong-network d-block d-md-none">
                        <Dropdown.Toggle className="btn btn-danger">{chainName} <span>Select Main Network</span></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => showDisconnectModel()} href="#/">Disconnect</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Dropdown className="menu-item d-block d-md-none">
                        <Dropdown.Toggle variant="link" className="wallet-info">
                          <span className="provider"><img src={getCurrencyURL()} alt="" /> {chainName}</span>
                          <span className="wallet">{accountAdd} <span className="identication" id="accountIdentication-mobile"></span></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => headerClickHandle(4, '/my-collection')} href="#/">My Collection</Dropdown.Item>
                          {/* <Dropdown.Item onClick={() => reconnectWallet(false)} href="#/">Change</Dropdown.Item> */}
                          <Dropdown.Item onClick={() => showDisconnectModel()} href="#/">Disconnect</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  }
                </div>
              ) : (
                <div className="connect-btn d-block d-md-none">
                  <ConnectButton
                    className="btn btn-primary"
                    isModal={true}
                  />
                </div>
              ) : <></>}
            <Navbar.Toggle className="menu-bar" aria-controls="site-navbars" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
              <span></span>
              <i className="sr-only">Menu Toggle</i>
            </Navbar.Toggle>
            <Navbar.Collapse className="site-menu" id="site-navbars">
              <nav >
                <ul className="menu navbar-nav align-items-center">
                  <li
                    onClick={() => headerClickHandle(0, '')}
                    className={`menu-item ${headerHighlight === 0 ? 'active' : ''}`}
                  >
                    <span>Buy Token</span>{' '}
                  </li>

                  <li
                    onClick={() => headerClickHandle(5, '/magicbox')}
                    className={`menu-item ${headerHighlight === 5 ? 'active' : ''}`}
                  >
                    {' '}
                    <span>Buy NFT</span>
                  </li>

                  {
                    isConnected && chainName !== 'Wrong Network' ? (
                      <li
                        onClick={() => headerClickHandle(1, '/my-collection')}
                        className={`menu-item ${headerHighlight === 1 ? 'active' : ''}`}
                      >
                        {' '}
                        <span>My Collection</span>
                      </li>) : (<></>)
                  }
                  {
                    isConnected && chainName !== 'Wrong Network' ? (
                      <li
                        onClick={() => headerClickHandle(6, '/staking')}
                        className={`menu-item ${headerHighlight === 6 ? 'active' : ''}`}
                      >
                        {' '}
                        <span>Stake</span>
                      </li>) : (<></>)
                  }

                  {isButton ?

                    isConnected ? (
                      <li className="menu-item last-item d-none d-md-inline-block">
                        <div className="btn-group">
                          {
                            chainName === 'Wrong Network' ? (
                              <Dropdown className="wrong-network">
                                <Dropdown.Toggle className="btn btn-danger">{chainName} <span>Select Polygon Main Network</span></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => showDisconnectModel()} href="#/">Disconnect</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              <Dropdown className="last-item menu-item d-none d-md-inline-block">
                                <Dropdown.Toggle variant="link" className="wallet-info">
                                  <span className="provider"><img src={getCurrencyURL()} alt="" /> {chainName}</span>
                                  <span className="wallet">{accountAdd} <span className="identication" id="accountIdentication"></span></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => headerClickHandle(4, '/my-collection')} href="#/">My Collection</Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => reconnectWallet(false)} href="#/">Change</Dropdown.Item> */}
                                  <Dropdown.Item onClick={() => showDisconnectModel()} href="#/">Disconnect</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )
                          }
                        </div>
                      </li>
                    ) : (
                      <li className="last-item menu-item d-none d-md-inline-block">
                        <ConnectButton
                          className="btn btn-primary"
                          isModal={true}
                        />
                      </li>
                    )
                    : <></>}
                </ul >
              </nav >
            </Navbar.Collapse >
          </Navbar >
        </div >
      </header >
      <Modal show={show} onHide={handleClose} centered className="account-modal">
        <Modal.Header>
          <Modal.Title>Account</Modal.Title>
          <button className="close-btn" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sc-fMiknA hTHyXD"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="box">
            <div className="top d-flex justify-content-between align-items-center">
              Connected with {providerName}
              <Button onClick={() => showDisconnectModel()} variant="outline-primary">
                Disconnect
              </Button>
            </div>
            <h3>
              <img src="./assets/img/coinbase-wallet.png" alt="Coinbase Wallet Logo" /> {accountAdd}
            </h3>
            <a href="/#" className="link" onClick={copyAddress}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                </svg>
              </span>
              {copyText}
            </a>
            <a href="/#" className="link" onClick={openExplorer}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </span>
              View on Explorer
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalShow} className="site-modal" centered>
        <Modal.Header>
          <Modal.Title>Select Network</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle className={currencyURL === '' ? 'select-network' : ''} >{currencyURL !== '' ? <img className="currency-icon" alt="" src={currencyURL} /> : <></>}{selectCurrency} <img className="arrow" alt="" src="/assets/img/down-arrow.png" /></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateCurrencyRequest(1)}><img className="currency-icon" src="/assets/img/eth.png" alt="ETH" /> Ethereum Mainnet</Dropdown.Item>
              <Dropdown.Item onClick={() => updateCurrencyRequest(2)} ><img className="currency-icon" src="/assets/img/matic.png" alt="Polygon Main Network" /> Polygon</Dropdown.Item>
              <Dropdown.Item onClick={() => updateCurrencyRequest(3)}><img className="currency-icon" src="/assets/img/bnb.png" alt="BNB" /> Binance Smartchain</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="primary" className="button" size="lg" onClick={confirmCurrency}>Confirm</Button>
        </Modal.Body>
      </Modal>
      <Modal show={infoModalShow} className="site-modal info-modal" centered>
        <Modal.Body>
          <p>Please switch to {getSelectedChainName()} Mainnet Network on your mobile App.</p>
          {selectCurrency !== ' ETH' ? (<><p>If you do not have the {getSelectedChainName()} network added, Please follow the below guide to add the {getSelectedChainName()} network in your mobile app <a onClick={openReferenceLink} href="#/">click here.</a><br /></p>
            <p>After switching to the {getSelectedChainName()} Network, please click on the 'reconnect' button below to get started with the {getSelectedChainName()} chain.</p></>) : (<></>)}
          <div className="bottom">
            <Button variant="dark" onClick={infoHandleClose}>Close</Button>
            <Button variant="primary" onClick={() => reconnectWallet(true)}>Reconnect</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
