import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import eventBus from './EventBus'
import axios from "axios";
import ConnectButton from './ConnectButton.js'
import * as lepaConstant from './Constant'
import { useHistory } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function Magicbox() {

    const [show, setShow] = useState(false);
    const [isConnected, updateConnection] = useState(false)
    const [isWrongNetwork, updateWrongNetwork] = useState(false)
    const [double, setDouble] = useState(false)
    const [ethBalance, updateETHBalance] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [lepaBalance, updateLepaBalance] = useState(null)
    const [openMagicBoxRequest, updateMagicBoxRequest] = useState(true)
    const [approvalRequest, updateApprovalReqauest] = useState(false)
    const [boxNumber, setBoxNumber] = useState(1)
    const [transactionHash, setTransactionHash] = useState(null)
    const [totalLepaAmount, setTotalLepa] = useState(null)
    const [collectionList, setCollectionList] = useState([])
    const history = useHistory()

    var apiCalling = 0

    useEffect(() => {
        if (window.localStorage.getItem('accountAddress') !== null) {
            updateConnection(true)
        }
        listenEvents()
    }, [])

    function approvalRequestButton() {
        doubleCheck()
        eventBus.dispatch('approvalRequestEvent', {})
        updateApprovalReqauest(true)
    }

    function listenEvents() {

        setTimeout(() => {
            if (window.localStorage.getItem('accountAddress') !== null) {
                eventBus.dispatch('requestETHBalance', { address: window.localStorage.getItem('accountAddress') })
            }
        }, 500)

        eventBus.on('wrongNetwork', (params) => {
            updateWrongNetwork(params.data)
        })

        eventBus.on('accountConnectedHeader', (params) => {
            updateConnection(true)
            eventBus.dispatch('requestETHBalance', { address: params.data })
            // eventBus.dispatch('requestETHBalance', { address: window.localStorage.getItem('accountAddress') })
        })

        eventBus.on('lepaBalance', (params) => {
            updateLepaBalance(params.balance + " LEPA")
        })

        eventBus.on('ethBalance', (params) => {
            updateETHBalance(params.balance + " " + getChainCurrency())
        })

        eventBus.on('updateConnectionEvent', (params) => {
            updateConnection(params.data)
        })

        eventBus.on('requestMagicboxGranted', (params) => {
            if (apiCalling === 0) {
                resetAPICalling()
                setDouble(false)
                if (params.data) {
                    setShow(true)
                    updateTransactionHash(params.mintId, params.transactionHash)
                } else {
                    updateTransactionHash(params.mintId, params.transactionHash)
                }
            }
        })

        eventBus.on('requestMagicboxSuccess', (params) => {
            if (apiCalling === 0) {
                resetAPICalling()
                setShow(false)
                updateTransactionReceipt(params.mintId, params.status)
            }

        })

        eventBus.on('sendAccountEvent', (params) => {
            updateMagicBoxRequest(true)
            updateApprovalReqauest(false)
            // eventBus.dispatch('registrationCheckEvent', { data: true })
            // console.log("Account changed")
        })

        eventBus.on('approvalRequestGranted', (params) => {
            // if (apiCalling === 0) {
            setDouble(false)
            if (!params.data) {
                updateApprovalReqauest(false)
            } else {
                collectionRequest()
            }
            // resetAPICalling()
            // }
        })
    }

    function resetAPICalling() {
        apiCalling = apiCalling + 1
        setTimeout(() => {
            apiCalling = 0
        }, 800)
    }

    function updateTransactionReceipt(mintId, status) {
        const txStatus = status ? "SUCCESS" : "FAILED"
        const temp = JSON.stringify({ "mintId": mintId, "transactionHash": transactionHash, "txStatus": txStatus })

        axios.post(lepaConstant.BASE_URL + "nft/submit-transaction", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                setDouble(false)
                if (status) {
                    history.push('my-collection')
                } else {
                    toast.error("Transaction Failed.")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function updateTransactionHash(mintId, transactionHash) {
        const txStatus = null !== transactionHash ? "PENDING" : "REJECTED"
        const temp = JSON.stringify({ "mintId": mintId, "transactionHash": transactionHash, "txStatus": txStatus })
        setTransactionHash(transactionHash)
        axios.post(lepaConstant.BASE_URL + "nft/submit-transaction", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                if (null !== transactionHash) {
                    // history.push('my-collection')
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function getCurrencyImageURL() {
        let chainName = window.localStorage.getItem('chainNameString')
        let url = "/assets/img/eth.png"
        if (chainName === "Matic") {
            url = "/assets/img/matic.png"
        } else if (chainName === "Binance") {
            url = "/assets/img/bnb.png"
        }
        return url
    }

    function getCurrencyURL() {
        let chainName = window.localStorage.getItem('chainNameString')
        let url = "https://etherscan.io/tx/"
        if (chainName === "Matic") {
            url = "https://polygonscan.com/tx/"
        } else if (chainName === "Binance") {
            url = "https://bscscan.com/tx/"
        }
        return url
    }

    function getTestnetCurrencyURL() {
        let chainName = window.localStorage.getItem('chainNameString')
        let url = "https://rinkeby.etherscan.io/tx/"
        if (chainName === "Matic") {
            url = "https://mumbai.polygonscan.com/tx/"
        } else if (chainName === "Binance") {
            url = "https://testnet.bscscan.com/tx/"
        }
        return url
    }

    function handleError(error) {
        toast.error(error)
    }

    function getCurrentTimestamp() {
        var latest = new Date()
        return latest.getTime()
    }

    function collectionRequest() {
        if (undefined !== getGlobal(lepaConstant.TOKEN) && getGlobal(lepaConstant.EXPIRY_TIME) > getCurrentTimestamp()) {
            getBoxList()
        } else {
            authRequest()
        }
    }

    function authRequest() {
        axios.post(lepaConstant.BASE_URL + "users/auth", {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                let token = response.data.token
                setGlobal(lepaConstant.TOKEN, token)
                var today = new Date();
                today.setHours(today.getHours() + 2)
                setGlobal(lepaConstant.EXPIRY_TIME, today.getTime())
                getBoxList()
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function getBoxList() {
        const json = JSON.stringify({ "address": getGlobal("accountAddress") })
        axios.post(lepaConstant.BASE_URL + "nft/get-available-boxes", json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                setCollectionList(response.data.data)
                updateMagicBoxRequest(false)
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401")
                    authRequest()
                else
                    handleError(error.response.data.message)
            })
    }


    useEffect(() => () => removeEvents(), [])

    function removeEvents() {
        eventBus.remove("accountBalEvent")
        eventBus.remove("ethBalance")
        eventBus.remove("approvalRequestGranted")
        eventBus.remove("requestMagicboxGranted")
        eventBus.remove("requestMagicboxSuccess")

        eventBus.remove("wrongNetwork")
        eventBus.remove("updateConnectionEvent")
        eventBus.remove("sendAccountEvent")
    }

    function setGlobal(key, value) {
        window.localStorage.setItem(key, value)
    }

    function getGlobal(name) {
        return window.localStorage.getItem(name)
    }

    function processMagicBox(boxObject) {
        const json = JSON.stringify({ "userAddress": getGlobal("accountAddress"), "boxCount": boxNumber, "boxId": boxObject.id })
        axios.post(lepaConstant.BASE_URL + "nft/process-box", json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                let total = boxObject.lepaPrice * boxNumber
                setTotalLepa(total)
                eventBus.dispatch('requestMagicbox', { data: response.data.data })
            })
            .catch(function (error) {
                setDouble(false)
                handleError(error.response.data.message)
                updateMagicBoxRequest(true)
                updateApprovalReqauest(false)
                console.log(error);
            })
    }

    function doubleCheck() {
        setDouble(true)
        setTimeout(() => {
            setDouble(false)
        }, 800)
    }

    function confirmTransaction() {
        let urlScan = getTestnetCurrencyURL() + transactionHash
        window.open(urlScan)
    }

    function openMagicBox(index) {
        if (null != lepaBalance) {
            doubleCheck()
            let boxObject = collectionList[index]
            if (boxObject.maxCount < boxNumber) {
                toast.error("Limit Exceeded. Your maximum buy limit is " + boxObject.maxCount + " NFTs.")
            } else {
                if (boxObject.isVIP === 0) {
                    if (boxObject.publicQty < boxNumber) {
                        toast.error("Limit Exceeded. Maximum availability is " + boxObject.publicQty + " NFTs.")
                        return
                    }
                } else {
                    if (boxObject.publicQty + boxObject.vipQty < boxNumber) {
                        toast.error("Limit Exceeded. Maximum availability is " + (boxObject.publicQty + boxObject.vipQty) + " NFTs.")
                        return
                    }
                }

                let total = boxObject.lepaPrice * boxNumber
                let lepaBal = lepaBalance.replace(" LEPA", "")
                if (Number(lepaBal) >= Number(total)) {
                    setCollectionName(boxObject.name)
                    processMagicBox(boxObject)
                } else {
                    toast.error("You don't have sufficient balance.")
                }
            }
        } else {
            toast.error("Fetching lepa balance.")
        }
    }

    function getCollectionImage() {
        return collectionList[0].imageURL
    }

    function decreaseBoxNumber() {
        if (boxNumber !== 1) {
            setBoxNumber(boxNumber - 1)
        }
    }

    function getUpdatedValue(value) {
        return boxNumber * value
    }

    function increaseBoxNumber() {
        setBoxNumber(boxNumber + 1)
    }

    function getChainName() {
        let chainName = window.localStorage.getItem('chainNameString')
        let result = "Ethereum"
        if (chainName === "Matic") {
            result = "Polygon"
        } else if (chainName === "Binance") {
            result = "Binance"
        }
        return result
    }

    function getChainCurrency() {
        let chainName = window.localStorage.getItem('chainNameString')
        let result = "ETH"
        if (chainName === "Matic") {
            result = "MATIC"
        } else if (chainName === "Binance") {
            result = "BNB"
        }
        return result
    }

    function getMessage() {
        if (null !== totalLepaAmount)
            return "Receiving " + boxNumber + " " + collectionName
    }

    return (
        <div className="site-content">
            <Header />
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="header-block"></div>

            <main className="main-content">
                <section className="vip-banner section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-right order-md-2">
                                {openMagicBoxRequest ?
                                    <img src="/assets/img/magicbox-collection.png" className='bannerMainImg' alt="" />
                                    : <img src={getCollectionImage()} className='bannerMainImg' alt="" />}
                            </div>
                            <div className="col-md-6 order-md-1">
                                {openMagicBoxRequest ?
                                    <div className="vip-page-top"><div className="page-top text-left headingLeftAlign"><h1>Magicbox</h1></div></div>
                                    : <></>}
                                {!isConnected ? <>
                                    <br />
                                    <p className="p1 mr-md-4 magicBoxBannerParag">Grab your NFTs from Magicbox with only $Lepa tokens. lets check what’s there for you.</p>
                                    <ConnectButton
                                        className="btn btn-primary btn-lg"
                                    />

                                </> : openMagicBoxRequest ? <>
                                    <br />
                                    <p className="p1 mr-md-4" style={{ color: '#f44336' }}>MagicBox is closed now. You will be notified for next slots.</p>

                                    {/* {!isWrongNetwork ? (

                                        <div className="balance-box">
                                            <div className="box">
                                                {null !== lepaBalance ? (
                                                    <>
                                                        <h2><img src={getCurrencyImageURL()} alt={getChainCurrency()} /> {getChainName()} Mainnet</h2>
                                                        <div className="group">
                                                            <label>Balance {getChainCurrency()}</label>
                                                            <input type="text" value={ethBalance} disabled />
                                                        </div>
                                                        <div className="group">
                                                            <label>Balance LEPA</label>
                                                            <input type="text" value={lepaBalance} disabled />
                                                        </div></>
                                                ) : <>
                                                    <div className="pre-loader"><img src="/assets/img/pre-loader.svg" alt="loader" className="loader" /></div>
                                                </>}
                                            </div>
                                            {null !== lepaBalance ? (
                                                !approvalRequest ?
                                                    <Button disabled={double} className="btn btn-primary btn-lg" onClick={() => approvalRequestButton()} >Approve</Button>
                                                    :
                                                    <Button className="btn btn-gray btn-lg btn-loader" disabled>Waiting For Approval</Button>

                                            ) : <></>}
                                        </div>
                                    ) : <>
                                        <Button className="btn btn-danger btn-lg" >Wrong Network</Button>
                                    </>} */}
                                </> :
                                    <>
                                        {collectionList.map((value, index) => {
                                            return <div className="magicbox" key="uniqueId{index}">
                                                {value.isVIP === 1 ?
                                                    <span className='headIcon'>
                                                        &nbsp;
                                                        <img src="/assets/img/vip-1.svg" alt="spinner" />
                                                    </span>
                                                    : <></>}
                                                <div className="head d-flex justify-content-between align-items-center">
                                                    <h2>{value.name}

                                                    </h2>
                                                    <div className="value"><span><img src="/assets/img/wallet.svg" alt="spinner" /> :</span> {lepaBalance}</div>
                                                </div>
                                                <div className='supplyDetails'>
                                                    <div className='supplyInner'>
                                                        <div className='supplyInfo'>Total Supply</div>
                                                        <div className="supplyValue">{value.totalQty}</div>
                                                    </div>
                                                    <div className='supplyInner'>
                                                        <div className='supplyInfo'>Availabile Supply</div>
                                                        <div className="supplyValue">{value.publicQty}</div>
                                                    </div>
                                                    <div className='supplyInner'>
                                                        <div className='supplyInfo'>VIP Reserved</div>
                                                        <div className="supplyValue">{value.vipQty}</div>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    {/* <OverlayTrigger placement="bottom"
                                                        overlay={
                                                            <Tooltip id="boxTooltip">{value.description}</Tooltip>
                                                        }>
                                                        <h3>Select Boxes</h3>                                                
                                                    </OverlayTrigger> */}
                                                    <div className="qty-block">
                                                        <div className="decrease action disable" onClick={() => decreaseBoxNumber()}></div>
                                                        <div className="count">{boxNumber}</div>
                                                        <div className="increase action" onClick={() => increaseBoxNumber()}></div>
                                                    </div>
                                                    <div className='d-flex magicBoxInfo'>
                                                        <div className='priceTxt'><div className='infoBox'>Price</div> <div className='valBox'>{getUpdatedValue(value.lepaPrice)} Lepa</div></div>
                                                        <div className='priceTxt'><div className='infoBox'>Limit</div> <div className='valBox'>{value.totalCount} </div></div>
                                                        <div className='priceTxt'><div className='infoBox'>Remaining</div> <div className='valBox'>{value.maxCount} </div></div>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    {
                                                        value.isVIP === 0 ?
                                                            value.publicQty === 0 ? <Button className="btn btn-danger btn-block btn-lg">SOLD OUT</Button>
                                                                : <Button disabled={double} onClick={() => openMagicBox(index)} className="btn btn-primary btn-block btn-lg">Swap</Button>
                                                            :
                                                            value.publicQty + value.vipQty === 0 ? <Button className="btn btn-danger btn-block btn-lg">SOLD OUT</Button>
                                                                : <Button disabled={double} onClick={() => openMagicBox(index)} className="btn btn-primary btn-block btn-lg">Swap</Button>
                                                    }

                                                    {/* <Button onClick={() => { setShow(true); setTimeout(() => setShow(false), 500000) }} className="btn btn-primary btn-block btn-lg">Open Magicbox</Button> */}
                                                </div>
                                            </div>
                                        })}

                                        <Modal show={show} onHide={() => setShow(false)} backdrop="static" className="confirmation-modal" centered>
                                            <Modal.Body>
                                                <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                                                <h3>Waiting for confirmation</h3>
                                                <p>{getMessage()}</p>
                                                <p className="p2" onClick={() => confirmTransaction()}>View Transaction</p>
                                            </Modal.Body>
                                        </Modal>
                                    </>}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}
