import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import * as lepaConstant from './Constant'
import axios from "axios"

export default function Nft(props) {

    const [nftObject, setNFTObject] = useState(null)

    useEffect(() => {

        function findNFTDetail() {
            let fileName = props.match.params.fileName
            let modelId = props.match.params.modelId
            const json = JSON.stringify({ "bcIndex": modelId, "tokenId": fileName })
            axios.post(lepaConstant.BASE_URL + "nft/get-nft-detail", json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    let data = JSON.parse(response.data.token_json)
                    setNFTObject(data)
                })
                .catch(function (error) {
                    props.history.push('/not-found');
                })
        }


        findNFTDetail()
    }, [])


    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="main-content">
                    <section className="art-section section my-collection">
                        {null !== nftObject ?
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                        <div className="page-top">
                                            <h1>{nftObject.name}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-right order-md-1 relativeContainer nftBannerImg">
                                        <img src={nftObject.image} alt="" />
                                        <span className='cornerRoundYellow'>
                                        </span>
                                    </div>

                                    <div className="col-md-6 order-md-2">
                                        <p className="p1 mr-md-4">
                                            {nftObject.description}
                                        </p>
                                    </div>
                                </div>

                                <div className="row align-items-center nowrap bottomRowColl">
                                    {nftObject.attributes.map((attr, indexx) => {
                                        return <div className='collectionSpecification' key={`nftuniqueId${indexx}`}>
                                            <div className='topFeature'>{attr.trait_type}</div>
                                            {/* <div className='middleFeature'></div> */}
                                            <div className='rarityPercent'>{attr.value}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            : <></>}
                    </section>
                </main>
                <Footer />
            </div>

        </>
    )
}
