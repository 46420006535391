import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Token from "./Token";
import Team from "./Team";
import Nft from "./Nft";
import Stake from "./Stake";
import StakeReport from "./stake-report";
import StakeDetail from "./Stake-detail";
import Dashboard from "./Dashboard";
import MyCollection from "./MyCollection";
import Magicbox from "./Magicbox";
import FiestaReport from "./FiestaReport";
import DiscordCampaign from "./DiscordCampaign";
import NotFound from "./NotFound";
import GameReady from "./GameReady";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/nft/:modelId/:fileName" exact component={Nft} />
        <Route path="/token" exact component={Token} />
        <Route path="/staking" exact component={Stake} />
        <Route path="/stake-detail" exact component={StakeDetail} />
        <Route path="/stake-report" exact component={StakeReport} />
        <Route path="/team" exact component={Team} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/game-ready-nft" exact component={GameReady} />
        <Route path="/my-collection" exact component={MyCollection} />
        <Route path="/magicbox" exact component={Magicbox} />
        <Route path="/learn-lepasa-discord" exact component={DiscordCampaign} />
        <Route path="/fiesta-lepasa-report" exact component={FiestaReport} />
        <Route path="/not-found" exact component={NotFound} status={404} />
        <Route component={NotFound} status={404} />
      </Switch>
    </Router>
  );
}

export default App;
