import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function GameReady() {


    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="main-content">
                    <div className="art-section section my-collection game-ready">
                        <div className="container">
                            <div className="page-top">
                                <h1>Lepasa 3D game ready NFTs</h1>
                            </div>
                            <p align="center">
                                <iframe width="790" height="444" src="https://www.youtube.com/embed/lhtyDVHw3Ck?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </p>
                        </div>
                    </div>
                </main>

                <Footer />

            </div >
        </>
    )
}
