import React from 'react'
import Header from './Header'
import Footer from './Footer'


export default function FiestaReport() {
    return (
        <>
            <div className="site-content">
                <Header isConnectButton={true} />
                <div className="header-block"></div>
                <div style={{ textAlign: 'center' }}>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSf8NR08l1qR2sir5k0Lghmr-kY_XKtxGsUpPTk5jn0kMuwT1g/viewform?embedded=true"
                        width="100%" height="1950" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no">Loading…</iframe>
                </div>
                <Footer />

            </div>
        </>
    )
}
