import React, { useState, useEffect } from 'react'
import Header from './Header'
import { useHistory } from 'react-router-dom'
import Footer from './Footer'
import Web3 from 'web3'
import eventBus from './EventBus'
import { Modal } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast';

export default function StakeReport(props) {

    const history = useHistory()
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(true)
    const [double, setDouble] = useState(false)
    const [stakeDetail, setStakeDetail] = useState(null)
    const [withdrawText, setWithdrawText] = useState('WITHDRAW')
    const [transactionHash, setTransactionHash] = useState(null)
    const [selectedToken, setSelectedToken] = useState([])
    const [selectedTokenBal, setSelectedTokenBal] = useState(0)

    var timeValueReconnect = 0

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    useEffect(() => {
        if (window.localStorage.getItem('accountAddress') === null) {
            history.push("/")
        }
        init()
        listenEvents()
    }, [])

    useEffect(() => () => removeEvents(), [])

    function init() {
        setTimeout(() => {
            setLoader(true)
            eventBus.dispatch('stakeReportRequest', {})
        }, 1500)
    }

    function isTokenSelect(tokenId) {
        return selectedToken.indexOf(tokenId) > -1
    }

    function getCurrentAPY() {
        return getGlobalNumber("currentAPY")
    }

    function selectToken(tokenId, reward) {
        const index = selectedToken.indexOf(tokenId)
        var balance = 0
        if (index > -1) {
            selectedToken.splice(index, 1)
            balance = (Number(selectedTokenBal) - Number(getEtherFormat(reward))).toFixed(3)
            setSelectedTokenBal(balance)
        } else {
            selectedToken.push(tokenId)
            balance = (Number(selectedTokenBal) + Number(getEtherFormat(reward))).toFixed(3)
            setSelectedTokenBal(balance)
        }
        setWithdrawText("Loading...")
        setTimeout(() => {
            if (selectedToken.length > 0) {
                setWithdrawText("WITHDRAW (" + balance + ")")
            } else {
                setWithdrawText("WITHDRAW")
            }
        }, 20)

    }

    function removeEvents() {
        eventBus.remove("reportRequestGranted")
        eventBus.remove("reportRequestSuccess")
        eventBus.remove('stakeReportResponse')
    }

    function getEtherFormat(lepaToken) {
        if (lepaToken === "0")
            return 0
        return Number(Web3.utils.fromWei(String(lepaToken), 'ether')).toFixed(3)
    }

    function pad(n, z) {
        z = z || '0';
        n = n + '';
        return n.length >= 4 ? n : new Array(4 - n.length + 1).join(z) + n;
    }

    function getDateFormat(time) {
        var timestamp = time * 1000
        var date = new Date(timestamp)
        return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear()
    }

    function listenEvents() {

        eventBus.on('accoutsChanged', (params) => {
            apiCallingRestriction()
            if (timeValueReconnect === 1) {
                if (window.location.href.indexOf("stake-report") > -1) {
                    window.location.reload()
                }
            }
        })

        eventBus.on('stakeReportResponse', (params) => {
            if (timeValueReconnect === 0) {
                apiCallingRestriction()
                setLoader(false)
                if (params.data.size > 0) {
                    var responseData = Array.from(params.data.values());
                    setStakeDetail(responseData)
                } else {
                    setStakeDetail([])
                }
            }
        })

        eventBus.on('reportRequestGranted', (params) => {
            if (timeValueReconnect === 0) {
                apiCallingRestriction()
                setDouble(false)
                if (params.data) {
                    setShow(true)
                    setTransactionHash(params.txHash)
                    console.log("txHash ", params.txHash)
                } else {
                    toast.error(params.msg)
                }
            }
        })

        eventBus.on('reportRequestSuccess', (params) => {
            if (timeValueReconnect === 0) {
                apiCallingRestriction()
                setShow(false)
                setDouble(false)
                if (params.data) {
                    toast.success("Transaction completed")
                    window.location.reload()
                } else {
                    toast.error(params.msg)
                }
            }
        })
    }

    function getEndDate(stakeInfo) {
        var date = new Date((Number(stakeInfo.startTS) + getGlobalNumber("expireSeconds")) * 1000)

        return date.getDate() +
            "-" + (date.getMonth() + 1) +
            "-" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds()
    }

    function getStartDate(stakeInfo) {
        return getDateFormat(stakeInfo.startTS)
    }

    function getLockDate(stakeInfo) {
        var date = new Date((Number(stakeInfo.startTS) + getGlobalNumber("lockSeconds")) * 1000)

        return date.getDate() +
            "-" + (date.getMonth() + 1) +
            "-" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds()
    }

    function apiCallingRestriction() {
        timeValueReconnect = timeValueReconnect + 1
        setTimeout(() => {
            timeValueReconnect = 0
        }, 800)
    }

    function doubleCheck() {
        setDouble(true)
    }

    function getGlobalNumber(name) {
        return Number(window.localStorage.getItem(name))
    }

    function openTransactionHash() {
        if (null !== transactionHash) {
            let urlScan = "https://polygonscan.com/tx/" + transactionHash
            window.open(urlScan)
        }
    }

    function withdrawRequest() {        
        if (selectedToken.length > 0) {
            if (getGlobalNumber("closeTS") > (Date.now() / 1000)) {
                toast.error("Cannot claim while staking is live")
            } else {
                doubleCheck()
                eventBus.dispatch('withdrawRequestEvent', { data: selectedToken })
            }
        } else {
            toast.error("Please select NFTs to claim reward")
        }
    }

    function unstakeRequest() {        
        if (selectedToken.length > 0) {
            var confirmationUnstake = true
            var tokenIds = []
            for (var i = 0; i < stakeDetail.length; i++) {
                if (selectedToken.indexOf(stakeDetail[i].id) > -1) {
                    if ((Date.now() / 1000) < (Number(stakeDetail[i].tokenInfo['startTS']) + getGlobalNumber("lockSeconds"))) {
                        confirmationUnstake = false
                        tokenIds.push(stakeDetail[i].id)
                    }
                }
            }
            if (confirmationUnstake) {
                doubleCheck()
                eventBus.dispatch('unStakeRequestEvent', { data: selectedToken })
            } else {
                toast.error("NFTs " + tokenIds.toString() + " can be unstaked after the locking period is over")
            }
        } else {
            toast.error("Please select NFTs to unstake")
        }
    }

    return (
        <>
            <div className="site-content">
                <Header />
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className="header-block"></div>

                <main className="team-page main-content stakeDetalPage">
                    <div className='section'>
                        <div className='container'>
                            <div className='page-top'>
                                <h1>
                                    STAKE YOUR NFT<span className='smallLetter'>s</span>
                                </h1>
                            </div>
                            <p className='tableDetaiTopText text-center'>
                                <div className="reportHeadTxt">
                                    The below dashboard contains the list of all NFTs staked by your
                                    address along with the staking start date, Yield Maturity date,
                                    Locked up time and the earned yield as per the locked APY.
                                </div>

                                <div className="reportHeadTxt">
                                    With Lepasa staking protocol, you earn rewards every second and can
                                    claim them any time, refresh this screen to see the Unclaimed Rewards
                                    Increase every time. Just select the "Unclaimed" section against each NFT
                                    and click on withdraw to get your $LEPA rewards in your wallet.
                                </div>

                                <div className="reportHeadTxt">
                                    You can claim rewards any time, but NFTS are locked for
                                    a minimum of 90 days from the date of staking.
                                </div>

                            </p>

                            {
                                null !== stakeDetail ?
                                    <>


                                        {stakeDetail.length === 0 ?
                                            <div className="notFoundMsg">No staked NFTs</div>
                                            :
                                            <>
                                                <span className='leftCurrentApr'>
                                                    <div className='headingTopToolBox darkColorTxt'>APY: {getCurrentAPY()}%</div>
                                                    <br /><br />
                                                </span>
                                                <div className='bg'>
                                                    <div className='table-responsive'>
                                                        <table className='table table-striped stakeTableParent'>
                                                            <tbody>
                                                                <tr>
                                                                    <th><span className='thBorder'>#</span></th>
                                                                    <th><span className='thBorder'>Starting Date</span></th>
                                                                    <th><span className='thBorder'>Ending Date</span></th>
                                                                    <th><span className='thBorder'>Locked Till</span></th>
                                                                    <th><span className='thBorder'>Staked NFT</span></th>
                                                                    <th><span className='thBorder'>Claimed</span></th>
                                                                    <th><span className='thBorder'>Unclaimed</span></th>
                                                                </tr>
                                                                {stakeDetail.map((stake, i) => {
                                                                    return <tr key={`lepasa-stake-${i}`}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{getStartDate(stake.tokenInfo)}</td>
                                                                        <td>{getEndDate(stake.tokenInfo)}</td>
                                                                        <td>{getLockDate(stake.tokenInfo)}</td>
                                                                        <td>POLQUEEN #{pad(stake.id)}</td>
                                                                        <td>{getEtherFormat(stake.tokenInfo.claimed)} Lepa</td>
                                                                        <td onClick={() => selectToken(stake.id, stake.reward)}><a href='javascript:void(0)' className={`withdrawBtn ${isTokenSelect(stake.id) === true ? 'active' : ''}`}>{getEtherFormat(stake.reward)} Lepa</a></td>
                                                                    </tr>
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {double ?
                                                    <div className='tableBottomBtn'>
                                                        <a href='javascript:void(0)' className='btn btn-primary btn-lg withdrawBtnTable'>{withdrawText}</a>
                                                        <a href='javascript:void(0)' className='btn btn-primary btn-lg unstackBtnTable'>UNSTAKE</a>
                                                    </div> :
                                                    <div className='tableBottomBtn'>
                                                        <a href='javascript:void(0)' onClick={() => withdrawRequest()} className='btn btn-primary btn-lg withdrawBtnTable'>{withdrawText}</a>
                                                        <a href='javascript:void(0)' onClick={() => unstakeRequest()} className='btn btn-primary btn-lg unstackBtnTable'>UNSTAKE</a>
                                                    </div>}

                                                <div className="clainEnabMsg"><sup>*</sup> Claim will be enabled after the staking window gets closed.</div>
                                            </>
                                        }</>

                                    : <></>}


                        </div>
                    </div>
                </main>
                <Footer />
            </div>

            <Modal show={show} onHide={() => setShow(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Waiting for confirmation</h3>
                    <p>Withdraw request in process</p>
                    <p className="p2" onClick={openTransactionHash}>View Transaction</p>
                </Modal.Body>
            </Modal>

            <Modal show={loader} onHide={() => setLoader(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Fetching Staked NFTs...</h3>
                </Modal.Body>
            </Modal>
        </>
    )
}
