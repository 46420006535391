import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useHistory } from 'react-router-dom'
import Footer from './Footer'
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap'
import * as lepaConstant from './Constant'
import eventBus from './EventBus'
import axios from "axios"

export default function StakeDetail(props) {

    const [modalShow, stakeModal] = useState(false)
    const [modalShowPicVw, stakeModalPicView] = useState(false)

    const [imageView, setImageView] = useState(null)
    const [loader, setLoader] = useState(true)
    const [collection, setCollection] = useState(null)
    const [stakeNumber, setStakeNumber] = useState([])
    const [double, setDouble] = useState(false)
    const [transactionHash, setTransactionHash] = useState(null)
    const [buttonStakeName, setButtonStakeName] = useState("Approve")
    const [currentAPR, setCurrentAPR] = useState(0)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showApproval, setShowApproval] = useState(false);
    const [stakeTokenId, setStakeTokenId] = useState([])

    var apiCall = 0

    const history = useHistory()


    useEffect(() => {
        if (window.localStorage.getItem('accountAddress') === null) {
            history.push("/")
        }
        listenEvents()
    }, [])

    function disableDoublePrevent() {
        setDouble(true)
        setTimeout(() => {
            setDouble(false)
        }, 2000)
    }

    function apiCallingRestriction() {
        apiCall = apiCall + 1
        setTimeout(() => {
            apiCall = 0
        }, 800)
    }

    function stakeNFTApproval() {
        disableDoublePrevent()
        setShowApproval(true)
        eventBus.dispatch('stakeNFTApproval', {})
    }

    function stakeNFTRequest() {
        setDouble(true)
        stakingRequestServer()
    }

    useEffect(() => () => removeEvents(), [])

    function stakeModalShow() {
        if (stakeNumber.length > 0) {
            if ((Date.now() / 1000) > getGlobalNumber("closeTS")) {
                toast.error("Plan Expired")
            } else if ((getGlobalNumber("currCount") + stakeNumber.length) > getGlobalNumber("maxCount")) {
                toast.error("NFT Collection Staking limit exceeded")
            } else if ((getGlobalNumber("userStakeCnt") + stakeNumber.length) > getGlobalNumber("maxUsrStake")) {
                toast.error("User Staking limit exceeded")
            } else {
                stakeModal(true)
            }
        } else {
            toast.error("Please select NFTs for staking")
        }
    }

    function removeEvents() {
        eventBus.remove("stakeTokenIdResponse")
        eventBus.remove("accoutsChanged")
        eventBus.remove("currentAPRResponse")
        eventBus.remove("stakeApprovalRequestGranted")
        eventBus.remove("stakeNFTRequestGranted")
        eventBus.remove("stakeNFTRequestSuccess")
    }

    function listenEvents() {

        setTimeout(() => {
            eventBus.dispatch('stakeTokenIdRequest', {})
            eventBus.dispatch('currentAPRRequest', {})
            eventBus.dispatch('userCountRequest', {})
        }, 1500)

        eventBus.on('accoutsChanged', (params) => {
            apiCallingRestriction()
            if (apiCall === 1) {
                setLoader(true)
                eventBus.dispatch('currentAPRRequest', {})
                stakeModal(false)
                stakeModalPicView(false)
                eventBus.dispatch('stakeTokenIdRequest', {})
            }
        })

        eventBus.on('stakeTokenIdResponse', (params) => {
            apiCallingRestriction()
            if (apiCall === 1) {
                if (params.status) {
                    setStakeTokenId(params.data)
                    loadCollections(getGlobal('accountAddress'))
                } else {
                    toast.error("Error Fetching Staking Report.")
                }
            }
        })

        eventBus.on('currentAPRResponse', (params) => {
            if (params.status) {
                setCurrentAPR(params.data)
            } else {
                toast.error("Cannot get CurrentAPR. Please reload the page.")
            }
        })

        eventBus.on('stakeApprovalRequestGranted', (params) => {
            apiCallingRestriction()
            if (apiCall === 1) {
                if (params.data) {
                    setShowApproval(false)
                    setButtonStakeName("STAKE")
                } else {
                    toast.error("Something went wrong..!!")
                }
            }
        })

        eventBus.on('stakeNFTRequestSuccess', (params) => {
            apiCallingRestriction()
            if (apiCall === 1) {
                setShowConfirmation(false)
                if (params.data) {
                    stakingResponseServer(null, "SUCCESS")
                    toast.success("Staking done successfully")
                    reportPage()
                } else {
                    stakingResponseServer(null, "FAILED")
                    toast.error(params.msg)
                }
            }
        })

        eventBus.on('stakeNFTRequestGranted', (params) => {
            setDouble(false)
            apiCallingRestriction()
            if (apiCall === 1) {
                if (params.data) {
                    setTransactionHash(params.txHash)
                    hideModal()
                    setShowConfirmation(true)
                    stakingResponseServer(params.txHash, "PENDING")
                } else {
                    stakingResponseServer(null, "REJECTED")
                    toast.error(params.msg)
                }
            }
        })
    }

    function getImageURL(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        return jsonData.image
    }

    function isAlreadyStake(indexId) {
        const nftId = collection[indexId].token_id
        return stakeTokenId.indexOf(nftId) > -1
    }

    function selectNFT(indexId) {
        const nftId = collection[indexId].token_id
        const index = stakeNumber.indexOf(nftId)
        if (index > -1) {
            stakeNumber.splice(index, 1)
        } else {
            stakeNumber.push(nftId)
        }
    }

    function stakingRequestServer() {
        const temp = JSON.stringify({ "address": getGlobal('accountAddress'), "tokenIds": stakeNumber })
        axios.post(lepaConstant.BASE_URL + "nft/stake-nft", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                eventBus.dispatch('stakeNFTRequest', { indexes: stakeNumber })
                setGlobal("stakeId", response.data.data.id)
            })
            .catch(function (error) {
                setDouble(false)
                handleError(error.response.data.message)
            })
    }

    function stakingResponseServer(txHash, txStatus) {
        const temp = JSON.stringify({ "stakeId": parseInt(getGlobal("stakeId")), "transactionHash": txHash, "txStatus": txStatus })
        axios.post(lepaConstant.BASE_URL + "nft/stake-nft-status", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                console.log("Request Updated ", response)
            })
            .catch(function (error) {
                handleError(error.response.data.message)
            })
    }

    function confirmTransaction() {
        let urlScan = "https://polygonscan.com/tx/" + transactionHash
        window.open(urlScan)
    }

    function stakeModalPic(lepasa) {
        let jsonData = JSON.parse(lepasa.token_json)
        setImageView(jsonData.image)
        stakeModalPicView(true)
    }

    function viewHide() {
        stakeModalPicView(false)
    }

    function hideModal() {
        stakeModal(false)
    }

    function setGlobal(key, value) {
        window.localStorage.setItem(key, value)
    }

    function isMaticChain(chainId) {
        //Mainnet
        // return (chainId === 137 || chainId === '0x89')
        //Testnet    
        return (chainId === 80001 || chainId === '0x13881')
    }

    function isBinanceChain(chainId) {
        //Meinnet
        // return (chainId === 56 || chainId === '0x38')
        //Testnet
        return (chainId === 97 || chainId === '0x61')
    }

    function isETHChain(chainId) {
        //Meinnet
        // return (chainId === 1 || chainId === '0x1')
        //Testnet
        return (chainId === 4 || chainId === '0x4')
    }

    function getChainName() {
        return "MATIC"
        // let chainNameReturn = null
        // if (isMaticChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "MATIC"
        // else if (isBinanceChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "BNB"
        // else if (isETHChain(window.web3.currentProvider.chainId))
        //     chainNameReturn = "ETH"
        // return chainNameReturn
    }

    function loadCollections(addr) {
        const temp = JSON.stringify({ "address": addr, "chainName": getChainName() })
        axios.post(lepaConstant.BASE_URL + "nft/get-user-nft", temp, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getGlobal(lepaConstant.TOKEN)
            }
        })
            .then(function (response) {
                var body = response.data.data
                if (body.length > 0) {
                    var collectionReport = []
                    for (var i = 0; i < body.length; i++) {
                        if (body[i].mint_status === 'HARD-MINT') {
                            collectionReport.push(body[i])
                        }
                    }
                    setCollection(collectionReport)
                } else {
                    setCollection([])
                }

                setLoader(false)
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401")
                    authRequest()
                else
                    handleError(error.response.data.message)
            })
    }

    function getGlobal(name) {
        return window.localStorage.getItem(name)
    }

    function getGlobalNumber(name) {
        return Number(window.localStorage.getItem(name))
    }

    function handleError(error) {
        toast.error(error)
    }

    function authRequest() {
        axios.post(lepaConstant.BASE_URL + "users/auth", {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                let token = response.data.token
                setGlobal(lepaConstant.TOKEN, token)
                var today = new Date();
                today.setHours(today.getHours() + 2)
                setGlobal(lepaConstant.EXPIRY_TIME, today.getTime())

                loadCollections(getGlobal('accountAddress'))
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    function pad(n, z) {
        z = z || '0';
        n = n + '';
        return n.length >= 4 ? n : new Array(4 - n.length + 1).join(z) + n;
    }

    function reportPage() {
        history.push("/stake-report")
    }

    return (
        <>
            <div className="site-content">
                <Header />
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className="header-block"></div>

                <main className="team-page main-content stakeDetalPage">
                    <div className='section'>
                        <div className='container'>
                            <div className='page-top'>
                                <h1>
                                    STAKE YOUR NFT<span className='smallLetter'>s</span>
                                </h1>
                            </div>
                            <div className='bg stakesBox'>
                                <div className='topDetailStakes'>
                                    <div className='leftCurrentApr'>
                                        {null !== collection ?
                                            <>
                                                <div className='headingTopToolBox'>CURRENT APY: {currentAPR}%</div>
                                                <select className='stackSelect'>
                                                    <option>Polqueen({collection.length})</option>
                                                </select>
                                            </>
                                            : <></>}
                                    </div>
                                    <div className='rightButtons'>
                                        <button onClick={reportPage} className='btn btn-primary btn-lg bgNone'>REPORTS</button>
                                        {null !== collection && collection.length > 0 ?
                                            <button className='btn btn-primary btn-lg ml-4' onClick={() => stakeModalShow()}>STAKE</button>
                                            : <button className='btn btn-primary btn-lg ml-4' disabled>STAKE</button>}
                                    </div>
                                </div>
                                <div className='stakesListing'>
                                    {null !== collection ?
                                        collection.length === 0 ? <>

                                            <div className="notFoundMsg">No NFTs found for staking</div>
                                        </>
                                            :
                                            (
                                                collection.map((lepasa, i) => {
                                                    return <div className='stakeBoxCheck' key={`lepasa-stake-${i}`}>
                                                        <img src={getImageURL(lepasa)} onClick={() => stakeModalPic(lepasa)} alt='nfts' />
                                                        <div className='bottomSctionBox'>
                                                            <div className='codeLeftSide'>POLQUEEN #{pad(lepasa.token_id)}</div>
                                                            {/* {isAlreadyStake(i) ?
                                                                <div className='rightCheckInput'><input type="checkbox" disabled /></div>
                                                                :
                                                                <div className='rightCheckInput'><input type="checkbox" onClick={() => selectNFT(i)} /></div>
                                                            } */}

                                                            <div className='rightCheckInput'><input type="checkbox" onClick={() => selectNFT(i)} /></div>
                                                        </div>
                                                    </div>
                                                })) : <></>}



                                </div>
                            </div>

                            <div className="infoTxtBtm">
                                <div className="nftTxtMgs"><sup>*</sup> NFT minimum locking Period : 90 days</div>
                                <div className="nftTxtMgs"><sup>*</sup> Rewards are in $LEPA token with per second yield upto 365 days.</div>
                                <div className="nftTxtMgs"><sup>*</sup> Maximum User staking limit is 20.</div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div >
            <Modal show={modalShow} onHide={() => hideModal()} className="site-modal stakePrevModal" centered>
                <Modal.Header>
                    <Modal.Title>Stake Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='sc-fujyUd cFRqqZ web3modal-modal-card'>
                        <div className='sc-eCApGN cjAFRf web3modal-provider-wrapper'>
                            <table className='table text-center'>
                                <tbody>
                                    <tr>
                                        <th>#</th>
                                        <th>Staking</th>
                                        <th>Per NFT Value</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>{stakeNumber.length} {stakeNumber.length > 1 ? 'Polqueens' : 'Polqueen'}</td>
                                        <td>500 Lepa</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='totalValBox'>
                                Total Value: <span className='lepaVal'>{stakeNumber.length * 500} Lepa</span>
                            </div>
                            <div className='btmPayoutDtl'>
                                <div>Payout: <span className='semiBold'>every second</span></div>
                                <div>APY: <span className='semiBold'>{currentAPR}%</span></div>
                            </div>


                            <div className='btmStakeAction'>
                                {buttonStakeName === "Approve" ?
                                    <>
                                        <button disabled={double} className='btn btn-primary btn-lg' onClick={() => stakeNFTApproval()}>
                                            {buttonStakeName}
                                        </button>

                                        <button className='btn btn-primary btn-lg stakeDisable' disabled>
                                            Stake
                                        </button>

                                    </>
                                    :
                                    <>
                                        <button className='btn btn-primary btn-lg approvedBtn'>
                                            Approved <span className='checkSign'>&#10003;</span>
                                        </button>

                                        <button disabled={double} className='btn btn-primary btn-lg' onClick={() => stakeNFTRequest()}>
                                            Stake
                                        </button>
                                    </>
                                }


                            </div>

                            {buttonStakeName === "Approve" ?
                                <div className="stepInfoActive">
                                    <div className="stepFirstInfo active">
                                        <span className="stepCounter">
                                            <span className="onActiveHide ">1</span>
                                            <span className="onActiveShow">
                                                <img src="/assets/img/check.png" />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="stepSecondInfo">
                                        <span className="stepCounter">2</span>
                                    </div>
                                </div>
                                :
                                <div className="stepInfoActive activeGreen">
                                    <div className="stepFirstInfo active">
                                        <span className="stepCounter">
                                            <span className="onActiveHide ">1</span>
                                            <span className="onActiveShow">
                                                <img src="/assets/img/check.png" />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="stepSecondInfo">
                                        <span className="stepCounter">2</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modalShowPicVw} className="site-modal stakePrevModal" centered>
                <Modal.Body>
                    <div className='sc-fujyUd cFRqqZ web3modal-modal-card'>
                        <div className='sc-eCApGN cjAFRf web3modal-provider-wrapper'>
                            <div className='picViewBigPopup' onClick={() => viewHide()}>
                                <span className='popupCloser'>
                                    <img src='/assets/img/times-circle.svg' alt="Lepasa Circle" />
                                </span>
                                <img src={imageView} alt="Lepasa FullView" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Waiting for confirmation</h3>
                    <p>NFT Staking is in process</p>
                    <p className="p2" onClick={() => confirmTransaction()}>View Transaction</p>
                </Modal.Body>
            </Modal>

            <Modal show={showApproval} onHide={() => setShowApproval(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Waiting for approval</h3>
                </Modal.Body>
            </Modal>

            <Modal show={loader} onHide={() => setLoader(false)} backdrop="static" className="confirmation-modal" centered>
                <Modal.Body>
                    <div className="loader-img"><img src="/assets/img/iphone-spinner-2.gif" alt="spinner" /></div>
                    <h3>Loading NFTs...</h3>
                </Modal.Body>
            </Modal>
        </>
    )
}