import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Team() {

    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="team-page main-content">
                    <div className="bg section">
                        <div className="container">
                            <div className="page-top">
                                <h1>Laying foundation for sustainable futuristic opportunities with NFT &amp; D<span className="text-lowercase">e</span>F<span className="text-lowercase">i</span> elements</h1>
                                <p>A collective initiative by leading artists, blockchain veterans & crypto tech enthusiasts. Collaborating together with a long term vision for the mutual beneficial opportunities in the ecosystem.</p>
                            </div>
                            <div className="team-group">
                                <div className="row">
                                    <div className="team col-6">
                                        <img className="shadow" src="/assets/img/ashish-agarwal.png" alt="Ashish Agarwal" />
                                        <div className="info">
                                            <h3>Ashish Agarwal</h3>
                                            <p>Co-Founder <br /> <a href="https://www.linkedin.com/in/allianceashish/" target="_blank" rel="noreferrer"><img style={{ borderRadius: 0, width: "20px" }} alt="" src="/assets/img/linkedin.svg" /></a></p>
                                        </div>
                                    </div>
                                    <div className="team col-6">
                                        <img className="shadow" src="/assets/img/alok-joshi.png" alt="Alok Joshi" />
                                        <div className="info">
                                            <h3>Alok Joshi</h3>
                                            <p>Co-Founder <br /> <a href="https://www.linkedin.com/in/alokjoshi7/" target="_blank" rel="noreferrer"><img style={{ borderRadius: 0, width: "20px" }} alt="" src="/assets/img/linkedin.svg" /></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="container">
                            <h2 className="mb-5">Project Advisors</h2>
                            <div className="media mb-md-2 mb-2">
                                <div className="team">
                                    <img src="/assets/img/harish-bv.png" alt="Harish BV" />
                                </div>
                                <div className="media-body">
                                    <div className="info1">
                                        <h3>Harish BV</h3>
                                        <p>Co-Founder, Unocoin <br /> <a href="https://in.linkedin.com/in/bvharish" target="_blank" rel="noreferrer"><img alt="" src="/assets/img/linkedin.svg" /></a></p>
                                    </div>
                                    <p className="p2">Harish is pioneer of Indian crypto business with experience of more than 15 years in the financial and telecommunication industry. He is a Computer Science and Telecommunication Engineer by education. Harish is one of the most active participant and has played super important role into development and struggle of Indian crypto ecosystem establishment.<br /><br />
                                        Harish is also a co-founder of prominent Indian crypto exchange Unocoin. His experience of the industry will help Lepasa position well in the market.
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="media pt-md-4 pt-2">
                                <div className="team">
                                    <img src="/assets/img/naeem-kharbari.png" alt="Harish BV" />
                                </div>
                                <div className="media-body">
                                    <div className="info1">
                                        <h3>Naeem Karbhari</h3>
                                        <p>Barrister at 3 Temple Gardens, London, UK <br /> <a href="https://www.linkedin.com/in/naeem-karbhari-0a481868/" target="_blank" rel="noreferrer"><img alt="" src="/assets/img/linkedin.svg" /></a></p>
                                    </div>
                                    <p className="p2">Naeem practices in all areas of criminal law and exclusively defends as an advocate. Naeem is sort after by legal professionals in high page-count cases, given his ability to easily absorb and retain particular details, together with his ability to easily cross-reference documents. Naeem's ever curious and researcher nature has grown his interest in Blockchain and Digital Currencies. He has done a thorough research of the industry since its inception till date. He is always updated with the latest happening in the industry be it DeFi Revolution or the latest NFT Craze Both professional and lay clients have always found Naeem's easy-going manner, but the quietly persuasive and tenacious approach, helpful.</p>
                                </div>
                            </div>
                            <hr />
                            <div className="media pt-md-4 pt-2">
                                <div className="team">
                                    <img src="/assets/img/bertrand-juglas.png" alt="Harish BV" />
                                </div>
                                <div className="media-body">
                                    <div className="info1">
                                        <h3>Bertrand Juglas</h3>
                                        <p>DevOps Architect <br /> <a href="https://www.linkedin.com/in/bjuglas/" target="_blank" rel="noreferrer"><img src="/assets/img/linkedin.svg" alt="" /></a></p>
                                    </div>
                                    <p className="p2">Bert holds 24 years of experience on OpenSource and Linux systems through new technologies, especially DLT technologies like BlockChain and DAG. He has worked with major companies in the cloud and banking sectors, also robotics and IT.<br /><br /> Bert's experience will Lepasa make right tech decisions and execute them in right direction.</p>
                                </div>
                            </div>

                            <hr />
                            <div className="media pt-md-4 pt-2">
                                <div className="team">
                                    <img src="/assets/img/jaggu-prashad.png" alt="Jaggu Prashad" />
                                </div>
                                <div className="media-body">
                                    <div className="info1">
                                        <h3>Jaggu Prashad</h3>
                                        <p>Art Director &amp; Mentor, Christie's Listed Artist <br />
                                            <a href="https://www.art.com/gallery/id--a5235/jaggu-prasad-posters.htm" target="_blank" rel="noreferrer"><img alt="Art.com" src="/assets/img/art.svg" /></a>&nbsp;&nbsp;
                                            <a href="https://www.nytimes.com/2003/02/07/arts/art-in-review-jaggu-prasad-company-transcending-nature-page-by-page.html" target="_blank" rel="noreferrer"><img alt="NyTimes" src="/assets/img/nytimes.svg" /></a>&nbsp;&nbsp;
                                            <a href="https://www.christies.com/search?entry=JAGGU%20PRASAD&page=1&sortby=relevance&tab=sold_lots" target="_blank" rel="noreferrer"><img alt="Christies" src="/assets/img/christies.svg" /></a>
                                        </p>
                                    </div>
                                    <p className="p2">Jaggu Prasad is an Indian Asian Modern &amp; Contemporary artist born in 1963. His work has been Featured &amp; offered at auctions multiple times, across the renowned galleries, museums &amp; auction houses like Christie's London. At Lepasa he has been a driving force behind the beautiful realistic Fine Art 3D Art Work. He has been mentoring our great CGI team since the inception of the project. Jaggu Prasad has compelled many public, private, and corporate commissions in botanical art and illustrations since1990's in Australia, Singapore, Germany, France, and the United States.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="section">
                        <div className="container">
                            <h2 className="mb-4">Foundation</h2>
                            <div className="media">
                                <div className="team order-md-2">
                                    <img src="/assets/img/foundation.png" alt="Foundation" className="noborder" />
                                </div>
                                <div className="media-body order-md-1">
                                    <p className="p2">Switzerland is one of the first countries to introduce a law framework for cryptocurrencies and blockchain projects. By introducing not-so-complex digital concepts in the forms of NFT &amp; DeFi solutions the ecosystem being built in the crypto valley will facilitate mass adoption of blockchain &amp; cryptocurrencies.
                                        <br /><br />
                                        Lepasa Foundation will ensure decentralisation of the authority &amp; governance of the project &amp; eventually lay the founding stone for a larger audience to adapt cryptocurrencies &amp; take advantage of enormous possibilities.
                                        <br /><br />
                                        We already have initiated the process to establish Lepasa Foundation under Swiss law.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg section text-center">
                        <div className="container">
                            <h2>Top-notch Artworks &amp; Opportunities</h2>
                            <p className="p3 mt-4">State of the art top-notch artworks &amp; opportunities to grow within the system with embedded DeFi features. Lepasa is in a true sense the real decentralised community-driven NFT project with a long term vision to develop a complete ecosystem for the artists, gamers and crypto enthusiasts.</p>

                        </div>
                    </div>
                    <div className="section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/assets/img/new-opportunity.jpg" alt="" />
                                </div>
                                <div className="col-md-7 content1 order-2 order-md-1 align-self-center">
                                    <h3><span><strong>New </strong> Opportunity</span> to Crypto Entrants</h3>
                                    <p className="p3">NFT craze has taken the whole industry by storm. Digital &amp; fine art creators are creating digital art or different digital stuff and tokenizing it using NFTs to create value. In recent times, NFTs are heavily used for art purpose. To simplify these let’s say, by tokenizing tangible assets, investors can have more liquidity. However, there is a more productive use of NFTs that is getting popular and expected to go mainstream in near future. Virtual assets, like actual artwork, lands, and turning them into tokenized securities.</p>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-5">
                                    <img src="/assets/img/trust-and-reliability.jpg" alt="" />
                                </div>
                                <div className="col-md-7 content1 align-self-center">
                                    <h3><span><strong>Trust</strong> &amp; Reliability</span> with Advanced Smart Contracts</h3>
                                    <p className="p3">Non-fungible tokens and their smart contracts allow for detailed attributes to be added, like the identity of the owner, rich metadata, or secure file links. The potent of non-fungible tokens to immutably prove digital ownership is an important progression for an increasingly digital world. They could see blockchain's promise of trustless security applied to the ownership or exchange of almost any asset.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 order-1 order-md-2">
                                    <img src="/assets/img/environmental.jpg" alt="" />
                                </div>
                                <div className="col-md-7 content1 order-2 order-md-1 align-self-center">
                                    <h3><span><strong>Environmental </strong>Concerns </span> &amp; Sustainability</h3>
                                    <p className="p3">NFTs are growing in popularity which means they're also coming under increased scrutiny &amp; especially over their carbon footprint. NFTs aren't directly increasing the carbon footprint of Ethereum. <br />
                                        The way Ethereum keeps your funds and assets secure is currently energy-intensive but it's about to improve. Once improved, Ethereum's carbon footprint will be 99.98% better, making it more energy-efficient than many existing industries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />

            </div>
        </>
    )
}
