import React, { useState, useEffect } from 'react'
import Header from './Header'
import toast, { Toaster } from 'react-hot-toast'
import eventBus from './EventBus'
import 'owl.carousel/dist/assets/owl.carousel.min.css'

export default function Home() {


    const [isConnected, updateConnection] = useState(false)
    const [iframeSRC, setIframeSRC] = useState("https://app.uniswap.org/#/swap?outputCurrency=0xbBa6C7c7d673c48d90069ad2e9d2fE587fcB6bc3")

    const [modalShow, setModalShow] = useState(false)


    function buyLepa() {
        if (isConnected) {
            var temp = false;
            var chainNameString = window.localStorage.getItem("chainNameString")
            if (chainNameString === "ETH") {
                temp = true
                setIframeSRC("https://app.uniswap.org/#/swap?outputCurrency=0xbBa6C7c7d673c48d90069ad2e9d2fE587fcB6bc3")
            } else if (chainNameString === "Binance") {
                temp = true
                setIframeSRC("https://pancakeswap.finance/swap?outputCurrency=0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86")
            } else if (chainNameString === "Matic") {
                temp = true
                setIframeSRC("https://quickswap.exchange/#/swap?outputCurrency=0xF9a4BBAa7fA1DD2352F1A47d6d3fcfF259A6D05F")
            }

            if (!temp) {
                toast.error("Please switch to valid chain.")
                setModalShow(false)
            } else {
                setModalShow(true)
            }

        } else {
            toast.error("Please connect your wallet.")
        }
    }

    useEffect(() => {

        if (window.localStorage.getItem('accountAddress') !== null) {
            updateConnection(true)
        }

        window.scrollTo(0, 0)
        listenEvents()

        return () => {
            removeEvents()
        }
    }, [])

    function removeEvents() {
        eventBus.remove("accountBalEvent")
        eventBus.remove("accountConnectedMagicbox")
        eventBus.remove("updateConnectionEvent")
    }

    function closeImg() {
        setModalShow(false)
    }

    function listenEvents() {

        eventBus.on('accountBalEvent', (params) => {
            updateConnection(true)
        })

        eventBus.on('accountConnectedMagicbox', (params) => {
            updateConnection(true)
        })

        eventBus.on('updateConnectionEvent', (params) => {
            updateConnection(params.data)
        })
    }


    return (
        <>
            <div className="site-content">
                <Header />
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className="header-block"></div>

                <main className="main-content">
                    <section className="hero-banner">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="container">
                                    <div className="content">
                                        <span className="tag">ART . Land . Staking</span>
                                        <h1>Get World’s Finest 3D<br /> Metaverse Ready NFTs.
                                        </h1>

                                        <div className="tag-list">
                                            <span>#3D</span>
                                            <span>#GameReady</span>
                                            <span>#NotJustJPEGs</span>
                                        </div>

                                        <div className='buyLepaOuterBox'>
                                            <button type='button' className='btn btn-primary btn-lg' onClick={buyLepa}>BUY $LEPA</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </main>
            </div>


            {modalShow ?
                <>
                    <div className='iframeOuter'>
                        <span className='closeIcon' onClick={closeImg}>
                            <img src='../assets/img/close.svg' />
                        </span>
                        <iframe className="iframe-swap"
                            src={iframeSRC}
                            height="80%"
                            width="40%"
                        />
                    </div>
                </> : <></>
            }


        </>
    )
}
